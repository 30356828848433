._link {
    display: block;
    padding: 0.5rem;
    color: black;
    text-decoration: none;
    transition: color 0.3s ease-in-out, white 0.30s ease-in-out, black 0.30s ease-in-out;
}

#dropdowntitle {
    display: block;
    color: black !important;
    text-decoration: none;
    transition: color 0.3s ease-in-out, white 0.30s ease-in-out, black 0.30s ease-in-out;
}

._dropdownitem {
    display: block;
    padding: 0.5rem;
    color: black;
    text-decoration: none;
}

._brand {
    color: #e8cb0f !important;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.75rem !important;
    margin-left: 3.25rem;
    text-shadow: 1px 1px 3px black;
}

._brand:hover {
    color: black;
}

._link:hover {
    color: white;
}

#dropdowntitle:hover {
    color: white !important;
}

._dropdownitem:hover {
    background-color: rgb(255, 223, 0) !important;
}